import React, { useEffect, useState } from 'react';
import { FaExchangeAlt, FaRedo } from 'react-icons/fa';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BRLABuyHistoryController } from '../../controller/BRLABuyHistoryController/BRLABuyHistoryController';
import { BRLASellHistoryController } from '../../controller/BRLASellHistoryController/BRLASellHistoryController';
import { StakeHistoryController } from '../../controller/StakeHistoryController/StakeHistoryController';
import { UnstakeFreeSharesHistoryController } from '../../controller/UnstakeFreeSharesHistoryController/UnstakeFreeSharesHistoryController';
import { UnstakeLockedSharesHistoryController } from '../../controller/UnstakeLockedSharesHistoryController/UnstakeLockedSharesHistoryController';
import { UnstakePrivateSharesHistoryController } from '../../controller/UnstakePrivateSharesHistoryController/UnstakePrivateSharesHistoryController';

interface Transaction {
  type: string;
  amount: number;
  description: string;
  date: string;
}

const TransactionHistoryTable: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  const loadTransactions = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const [
        buyHistoryResponse,
        sellHistoryResponse,
        stakeHistoryResponse,
        unstakeFreeHistoryResponse,
        unstakeLockedHistoryResponse,
        unstakePrivateHistoryResponse,
      ] = await Promise.all([
        BRLABuyHistoryController(),
        BRLASellHistoryController(),
        StakeHistoryController(),
        UnstakeFreeSharesHistoryController(),
        UnstakeLockedSharesHistoryController(),
        UnstakePrivateSharesHistoryController(),
      ]);

      const parseEtherValue = (amountRaw: string) => parseFloat((parseInt(amountRaw) / 1e18).toFixed(2));

      const formatTransactions = (data: any, type: string, isPositive: boolean) =>
        Array.isArray(data)
          ? data
              .filter((item) => {
                if (type === 'Depósito') {
                  return (
                    item.mintOps &&
                    item.mintOps.some(
                      (op: any) => op.smartContractOps && op.smartContractOps.some((scOp: any) => scOp.feedback?.success === true)
                    )
                  );
                } else if (type === 'Saque') {
                  return item.smartContractOps && item.smartContractOps.some((op: any) => op.feedback?.success === true);
                } else {
                  return item.smartContractOps && item.smartContractOps.some((op: any) => op.feedback?.success === true);
                }
              })
              .map((item) => {
                let amount;

                if (type === 'Depósito') {
                  amount = parseFloat(item.amountRaw || item.amount) / 100;
                } else if (type === 'Saque') {
                  amount = -(parseFloat(item.amountRaw || item.amount) / 100);
                } else if (type === 'Stake') {
                  amount = (isPositive ? parseEtherValue(item.amountRaw || item.amount) : -parseEtherValue(item.amountRaw || item.amount)) * 20;
                } else {
                  amount = isPositive ? parseEtherValue(item.amountRaw || item.amount) : -parseEtherValue(item.amountRaw || item.amount);
                }

                return {
                  type,
                  amount,
                  description: `${type}`,
                  date: item.createdAt || item.date,
                };
              })
          : [];

      const combinedTransactions = [
        ...formatTransactions(buyHistoryResponse.data.depositsLogs, 'Depósito', true),
        ...formatTransactions(sellHistoryResponse.data.transfersLogs, 'Saque', false),
        ...formatTransactions(stakeHistoryResponse.data.stakeLogs, 'Stake', false),
        ...formatTransactions(unstakeFreeHistoryResponse.data.unstakeLogs, 'Unstake Free', true),
        ...formatTransactions(unstakeLockedHistoryResponse.data.unstakeLogs, 'Unstake Locked', true),
        ...formatTransactions(unstakePrivateHistoryResponse.data.unstakeLogs, 'Unstake Private', true),
      ];

      const sortedTransactions = combinedTransactions
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .slice(0, 15);

      setTransactions(sortedTransactions);
    } catch (error: any) {
      if (error.status === 401) {
        navigate('/login');
      } else {
        setHasError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTransactions();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Skeleton variant="rectangular" width="100%" height={400} />
      </div>
    );
  }

  if (transactions.length === 0) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center text-gray-600">
        <p className="mb-4">Nenhum dado encontrado.</p>
        <button onClick={loadTransactions} className="flex items-center text-blue-500 hover:text-blue-700">
          <FaRedo className="mr-2" />
          Tentar novamente
        </button>
      </div>
    );
  } else if (hasError) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center text-gray-600">
        <p className="mb-4">Não foi possível carregar todos os dados.</p>
        <button onClick={loadTransactions} className="flex items-center text-blue-500 hover:text-blue-700">
          <FaRedo className="mr-2" />
          Tentar novamente
        </button>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="space-y-4 divide-y divide-gray-200">
        {transactions.map((transaction, index) => (
          <div key={index} className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <FaExchangeAlt size={20} className="text-gray-500 mr-2" />
              <div>
                <p className="text-gray-500">{transaction.description}</p>
                <span className="text-xs text-gray-400">
                  {new Date(transaction.date).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}
                </span>
              </div>
            </div>
            <div className="text-right">
              <p className={`font-bold ${transaction.amount > 0 ? 'text-green-600' : 'text-red-600'}`}>
                {transaction.amount > 0 ? '+' : '-'}{' '}
                {transaction.type.includes('Unstake') ? '' : 'R$'} {Math.abs(transaction.amount).toFixed(2)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionHistoryTable;
