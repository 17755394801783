import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { FaCog, FaUser, FaPlusCircle, FaExchangeAlt, FaShare, FaLock, FaMoneyBill, FaFire, FaUnlock } from 'react-icons/fa';
import LogoBrla from '../../components/LogoBrla/LogoBrla';
import { useNavigate } from 'react-router-dom';
import { AccountInfoController } from '../../controller/AccountInfoController/AccountInfoController';
import ErrorFallback from '../KYC/ErrorFallback';
import { Skeleton } from '@mui/material';
import { BRLAContractAbi, tftcAbi } from '../../utils/abis';
import { BRLA_CONTRACT_ADDRESSES, POLYGONURLC, TFTC_IMPACT_BRLA_V2_CONTRACT } from '../../variaveis/variaveis';
import TransactionHistoryTable from './TransactionHistoryTable';

interface AccountInfo {
  id: string;
  email: string;
  walletAddress: string;
  createdAt: string;
  updatedAt: string;
  taxId: string;
  level: number;
  status: string;
}

interface StakeInfo {
  freeStakedSharesValueBrla: string;
  privateInvestmentSharesValueBrla: string;
  lockedStakedSharesValueBrla: string;
  cumulativeRedeemedLockedStakedValueBrla: string;
  initialLockedValueBrla: string;
  initialPrivateInvestmentValueBrla: string;
}

const Dashboard: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [infoData, setInfoData] = useState<AccountInfo | null>(null);
  const [balance, setBalance] = useState<number | null>(null);
  const [nftBalance, setNftBalance] = useState<number | null>(null);
  const [totalFreeStakedValue, setTotalFreeStakedValue] = useState(0);
  const [totalPrivateInvestmentValue, setTotalPrivateInvestmentValue] = useState(0);
  const [totalLockedStakedValue, setTotalLockedStakedValue] = useState(0);
  const [isLockedUnstakeDisabled, setIsLockedUnstakeDisabled] = useState(false);
  const [isLoadingOnchain, setIsLoadingOnchain] = useState(true);
  const [onchainError, setOnchainError] = useState(false);
  const navigate = useNavigate();

  const fetchAccountInfo = async () => {
    try {
      const info = await AccountInfoController();
      if (info && info.data) {
        setInfoData(info.data);
        setHasError(false);
      }
    } catch (error: any) {
      if (error.status === 401) {
        navigate('/login');
      } else {
        setHasError(true);
      }
    }
  };

  const fetchBalance = async () => {
    if (!infoData?.walletAddress) return;

    setIsLoadingOnchain(true);
    const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURLC));
    const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[80001];
    const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
    const tftcContract = new web3.eth.Contract(tftcAbi, TFTC_IMPACT_BRLA_V2_CONTRACT[80001]);

    try {
      const brlaBalance = await contract.methods.balanceOf(infoData.walletAddress).call();
      if (brlaBalance) {
        const balanceInEther = parseFloat(web3.utils.fromWei(brlaBalance.toString(), 'ether'));
        setBalance(balanceInEther);
      }

      const nftQuantity = await tftcContract.methods.balanceOf(infoData.walletAddress).call();
      if (nftQuantity) {
        const quantity = Number(nftQuantity);
        setNftBalance(quantity);

        let accumulatedFreeStakedValue = 0n;
        let accumulatedInitialPrivateInvestmentValue = 0n;
        let accumulatedLockedStakedValue = 0n;
        let accumulatedCumulativeRedeemedLockedStakedValueBrla = 0n;
        let accumulatedPrivateInvestmentSharesValueBrla = 0n;
        let accumulatedInitialLockedValueBrla = 0n;
        let accumulatedInitialPrivateInvestmentValueBrla = 0n;

        for (let i = 0; i < quantity; i++) {
          const nftId = await tftcContract.methods.tokenOfOwnerByIndex(infoData.walletAddress, i).call();
          const stakeinfo: StakeInfo = await tftcContract.methods.getStakeInfo(nftId).call();

          accumulatedFreeStakedValue += BigInt(stakeinfo.freeStakedSharesValueBrla);
          accumulatedPrivateInvestmentSharesValueBrla += BigInt(stakeinfo.privateInvestmentSharesValueBrla);
          accumulatedLockedStakedValue += BigInt(stakeinfo.lockedStakedSharesValueBrla);
          accumulatedCumulativeRedeemedLockedStakedValueBrla += BigInt(stakeinfo.cumulativeRedeemedLockedStakedValueBrla);
          accumulatedInitialLockedValueBrla += BigInt(stakeinfo.initialLockedValueBrla);
          accumulatedInitialPrivateInvestmentValueBrla += BigInt(stakeinfo.initialPrivateInvestmentValueBrla);
          console.log("accumulatedPrivateInvestmentSharesValueBrla: ", accumulatedPrivateInvestmentSharesValueBrla)
        }

        const divisor = BigInt(10 ** 18);
        const formatBigInt = (value: bigint, decimals: number) => {
          const integerPart = value / BigInt(10 ** decimals);
          const decimalPart = value % BigInt(10 ** decimals);
          return `${integerPart}.${decimalPart.toString().padStart(decimals, '0')}`;
        };

        setTotalFreeStakedValue(parseFloat(formatBigInt(accumulatedFreeStakedValue, 18)));
        setTotalPrivateInvestmentValue(parseFloat(formatBigInt(accumulatedPrivateInvestmentSharesValueBrla, 18)));
        setTotalLockedStakedValue(parseFloat(formatBigInt(accumulatedLockedStakedValue, 18)));

        setIsLockedUnstakeDisabled(
          accumulatedLockedStakedValue + accumulatedCumulativeRedeemedLockedStakedValueBrla <
          accumulatedInitialLockedValueBrla + accumulatedInitialPrivateInvestmentValueBrla
        );
      }
      setOnchainError(false);
    } catch (error) {
      console.error("Error fetching balance:", error);
      setOnchainError(true);
    } finally {
      setIsLoadingOnchain(false);
    }
  };

  useEffect(() => {
    fetchAccountInfo();
  }, [navigate]);

  useEffect(() => {
    if (infoData) {
      fetchBalance();
    }
  }, [infoData]);

  const handleWithdraw = (type: string) => {
    navigate(`/${type}`);
  };

  const totalBalance = (balance || 0) + totalFreeStakedValue + totalLockedStakedValue + totalPrivateInvestmentValue;

  if (!infoData || isLoadingOnchain) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Skeleton />
      </div>
    );
  }

  if (hasError || onchainError) {
    return <ErrorFallback onRetry={() => { fetchAccountInfo(); fetchBalance(); }} />;
  }

  return (
    <div className="w-full min-h-screen bg-gray-50 p-6 flex justify-center">
      <div className="max-w-6xl w-full">
        <div className="flex justify-between items-center pb-4 border-b border-gray-200">
          <div className="flex items-center">
            <LogoBrla />
          </div>
          <div className="flex space-x-8">
            <button onClick={() => navigate('/change-password')} className="text-gray-500 hover:text-black">
              <FaCog size={20} />
            </button>
            <button onClick={() => navigate('/profile')} className="text-gray-500 hover:text-black">
              <FaUser size={20} />
            </button>
          </div>
        </div>

        <div className="flex justify-start items-center mt-6">
          <span className="text-gray-500 text-sm">Balanço total</span>
          <h2 className="text-3xl font-bold text-black ml-4">R$ {totalBalance.toFixed(2)}</h2>
        </div>

        {/* Grid Responsivo para caixas de saldo */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-6 text-left">
          <div className="bg-gray-100 p-4 rounded-lg shadow-sm relative">
            <div className="flex items-center">
              <FaMoneyBill size={20} className="text-gray-500" />
              <span className="text-gray-500 ml-2">Saldo disponível</span>
            </div>
            <h3 className="text-xl font-bold text-black mt-2">R$ {balance?.toFixed(2)}</h3>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg shadow-sm relative">
            <div className="flex items-center">
              <FaUnlock size={20} className="text-gray-500" />
              <span className="text-gray-500 ml-2">Free shares</span>
            </div>
            <h3 className="text-xl font-bold text-black mt-2">R$ {totalFreeStakedValue.toFixed(2)}</h3>
            <button onClick={() => handleWithdraw('unstake-free')} className="absolute bottom-2 right-2 text-gray-500 hover:text-black">
              <div className='flex items-center'>
                <span className='font-bold text-black mr-2'>Resgate</span>
                <FaShare size={16} />
              </div>
            </button>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg shadow-sm relative">
            <div className="flex items-center">
              <FaLock size={20} className="text-gray-500" />
              <span className="text-gray-500 ml-2">Locked shares</span>
            </div>
            <h3 className="text-xl font-bold text-black mt-2">R$ {totalLockedStakedValue.toFixed(2)}</h3>
            <button 
              onClick={() => handleWithdraw('unstake-locked')} 
              className={`absolute bottom-2 right-2 text-gray-500 hover:text-black ${isLockedUnstakeDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled={isLockedUnstakeDisabled}
            >
              <div className='flex items-center'>
                <span className='font-bold text-black mr-2'>Resgate</span>
                <FaShare size={16} />
              </div>
            </button>
          </div>

          <div className="bg-gray-100 p-4 rounded-lg shadow-sm relative">
            <div className="flex items-center">
              <FaFire size={20} className="text-gray-500" />
              <span className="text-gray-500 ml-2">Private shares</span>
            </div>
            <h3 className="text-xl font-bold text-black mt-2">R$ {totalPrivateInvestmentValue.toFixed(2)}</h3>
            <button  
              onClick={() => handleWithdraw('unstake-private')}
              className="absolute bottom-2 right-2 text-gray-500 hover:text-black cursor-not-allowed opacity-50"
              disabled
            > 
              <div className='flex items-center'>
                <span className='font-bold text-black mr-2'>Resgate</span>
                <FaShare size={16} />
              </div>
            </button>
          </div>
        </div>

        <div className="flex justify-between items-center mt-8">
          <h3 className="text-2xl font-bold text-black">Transações</h3>
          <div className="flex space-x-4">
            <div className="flex flex-col items-center">
              <button onClick={() => navigate('/deposit')} className="bg-gray-100 p-5 rounded-full hover:bg-gray-200">
                <FaPlusCircle size={20} className="text-gray-500" />
              </button>
              <span className="text-sm text-gray-500 mt-2 font-bold">Depositar</span>
            </div>
            <div className="flex flex-col items-center">
              <button onClick={() => navigate('/investment')} className="bg-gray-100 p-5 rounded-full hover:bg-gray-200">
                <FaShare size={20} className="text-gray-500" />
              </button>
              <span className="text-sm text-gray-500 mt-2 font-bold">Investir</span>
            </div>
            <div className="flex flex-col items-center">
              <button onClick={() => navigate('/withdraw')} className="bg-gray-100 p-5 rounded-full hover:bg-gray-200">
                <FaExchangeAlt size={20} className="text-gray-500" />
              </button>
              <span className="text-sm text-gray-500 mt-2 font-bold">Sacar</span>
            </div>
          </div>
        </div>

        <div className="mt-6 bg-gray-100 rounded-lg shadow-sm overflow-hidden">
          <TransactionHistoryTable />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
