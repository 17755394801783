import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaMoneyBill, FaKey, FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import ErrorFallback from '../KYC/ErrorFallback';
import { AccountInfoController } from '../../controller/AccountInfoController/AccountInfoController';
import { BRLA_CONTRACT_ADDRESSES, POLYGONURLC } from '../../variaveis/variaveis';
import { BRLAContractAbi } from '../../utils/abis';
import Web3 from 'web3';
import { SellBRLAController } from '../../controller/SellBRLAController/SellBRLAController';
import LogoGreen from '../../components/LogoGreen';


interface AccountInfo {
    id: string;
    email: string;
    walletAddress: string;
    createdAt: string;
    updatedAt: string;
    taxId: string;
    level: number;
    status: string;
}

const Withdraw: React.FC = () => {
    const [pixKey, setPixKey] = useState('');
    const [amount, setAmount] = useState('');
    const [isPixKeyFormatted, setIsPixKeyFormatted] = useState(false);
    const [error, setError] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [hasError, setHasError] = useState(false);
    const [balanceError, setBalanceError] = useState(false);
    const [infoData, setInfoData] = useState<AccountInfo | null>(null);
    const [balance, setBalance] = useState<number | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBalance, setIsLoadingBalance] = useState(true);
    const navigate = useNavigate();

    const cpfValidate = (strCPF: string) => {
        const strCPFFormatted = strCPF.replace(/[-.]/g, '');
        if (strCPFFormatted.length !== 11 || /^(\d)\1+$/.test(strCPFFormatted)) return false;
        
        let Soma = 0;
        for (let i = 1; i <= 9; i++) Soma += parseInt(strCPFFormatted.substring(i - 1, i)) * (11 - i);
        let Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(strCPFFormatted.substring(9, 10))) return false;

        Soma = 0;
        for (let i = 1; i <= 10; i++) Soma += parseInt(strCPFFormatted.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        return Resto === parseInt(strCPFFormatted.substring(10, 11));
    };

    const validaCNPJ = (cnpj: string) => {
        const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
        const c = cnpj.replace(/[^\d]/g, '');

        if (c.length !== 14 || /0{14}/.test(c)) return false;

        let n = 0;
        for (let i = 0; i < 12; i++) n += parseInt(c[i], 10) * b[i + 1];
        const firstCheckDigit = (n % 11) < 2 ? 0 : 11 - (n % 11);
        if (parseInt(c[12], 10) !== firstCheckDigit) return false;

        n = 0;
        for (let i = 0; i <= 12; i++) n += parseInt(c[i], 10) * b[i];
        const secondCheckDigit = (n % 11) < 2 ? 0 : 11 - (n % 11);

        return parseInt(c[13], 10) === secondCheckDigit;
    };

    const isValidEmailFormat = (email: string) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

    const formatPixKey = (key: string): string => {
        const cleanedKey = isValidEmailFormat(key) ? key : key.replace(/[+().\s-]/g, '');

        if (cpfValidate(cleanedKey)) {
            return `${cleanedKey.slice(0, 3)}.${cleanedKey.slice(3, 6)}.${cleanedKey.slice(6, 9)}-${cleanedKey.slice(9)}`;
        }
        if (validaCNPJ(cleanedKey)) {
            return `${cleanedKey.slice(0, 2)}.${cleanedKey.slice(2, 5)}.${cleanedKey.slice(5, 8)}/${cleanedKey.slice(8, 12)}-${cleanedKey.slice(12)}`;
        }
        if (/^\d{11,12}$/.test(cleanedKey) && !cpfValidate(cleanedKey) && !validaCNPJ(cleanedKey)) {
            return `+55 (${cleanedKey.slice(0, 2)}) ${cleanedKey.slice(2, 7)}-${cleanedKey.slice(7)}`;
        }
        return cleanedKey;
    };

   const handlePixKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMessage("Por favor, insira uma chave PIX válida");
        setIsButtonDisabled(true);
        const input = e.target.value;
        setIsPixKeyFormatted(false);
        setPixKey(input);
    };

    const handlePixKeyValidation = () => {
        const cleanedKey = isValidEmailFormat(pixKey) ? pixKey : pixKey.replace(/[+().\s-]/g, '');
        const isValid = cpfValidate(cleanedKey) || validaCNPJ(cleanedKey) || /^\d{11,12}$/.test(cleanedKey) || isValidEmailFormat(pixKey);

        if (isValid && !isPixKeyFormatted) {
            setPixKey(formatPixKey(cleanedKey));
            setIsPixKeyFormatted(true);
            setErrorMessage('');
        } else if (!isValid) {
            setErrorMessage("Por favor, insira uma chave PIX válida");
            setIsPixKeyFormatted(false);
        }
        setIsButtonDisabled(!(isValid && parseFloat(amount) <= (balance || 0) && parseFloat(amount) >= 50));
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const amountNumber = parseFloat(value);
      
        setAmount(value);  
        if (amountNumber > (balance || 0)) {
            setError("O valor não pode exceder o saldo disponível.");
            setIsButtonDisabled(true);
        } else if (amountNumber < 50) {
            setError("O valor mínimo para saque é R$50.");
            setIsButtonDisabled(true);
        } else {
            setError('');
            setIsButtonDisabled(!(isPixKeyFormatted && amountNumber >= 50));
        }
    };

    const handleWithdraw = async () => {
        const amountNumber = parseFloat(amount);
        const amountCents = parseFloat((amountNumber * 100).toFixed(0));
        const data = {
            amount: amountCents,
            pixKey: pixKey,
        };
        
        setIsLoading(true);
        
        try {
            const result = await SellBRLAController(data);
            if (result.status === 201) {
                toast.success('Solicitação de saque realizada com sucesso!');
                setTimeout(() => navigate('/'), 3000);
            } else {
                toast.error(`Erro inesperado: ${result.status}`);
            }
            return result;
        } catch (error: any) {
            console.error('Erro durante a solicitação:', error.response?.data || error.message);
            toast.error('Erro ao realizar o saque: ' + (error.response?.data?.error || error.message));
        } finally {
            setIsLoading(false);
        }
    };



    const fetchAccountInfo = async () => {
        try {
            const info = await AccountInfoController();
            if (info && info.data) {
                setInfoData(info.data);
                setHasError(false);
            }
        } catch (error: any) {
            if (error.status === 401) {
                navigate('/login');
            } else {
                setHasError(true);
            }
        }
    };

    const fetchBalance = async () => {
        if (!infoData?.walletAddress) return;
    
        const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURLC));
        const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[80001];
        const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
    
        try {
            const brlaBalance = await contract.methods.balanceOf(infoData.walletAddress).call();
            if (brlaBalance) {
                const balanceInEther = parseFloat(web3.utils.fromWei(brlaBalance.toString(), 'ether'));
                setBalance(balanceInEther);
                setBalanceError(false);
            }
        } catch (error) {
            console.error("Error fetching balance:", error);
            setBalanceError(true);
        } finally {
            setIsLoadingBalance(false);
        }
    };
    
    useEffect(() => {
        fetchAccountInfo();
    }, [navigate]);

    useEffect(() => {
        if (infoData) {
            fetchBalance();
        }
    }, [infoData]);

    if (!infoData || isLoadingBalance) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Skeleton />
            </div>
        );
    }

    if (hasError || balanceError) {
        return <ErrorFallback onRetry={() => { fetchAccountInfo(); fetchBalance(); }} />;
    }

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
            <div className="max-w-md w-full flex flex-col items-start">
                <button onClick={() => navigate('/')} className="flex items-center text-gray-700 hover:text-gray-900 mb-4">
                    <FaArrowLeft className="mr-2" />
                    <span>Voltar para o Dashboard</span>
                </button>

                <div className="w-full bg-white rounded-lg shadow-lg p-6">
                    <LogoGreen />
                    <h2 className="text-2xl font-semibold text-gray-700 text-center mb-4 mt-4">Sacar saldo</h2>
                    
                    <div className="mb-6 text-center">
                        <span className="text-gray-500">Saldo disponível</span>
                        <h3 className="text-3xl font-bold text-black">R$ {(Math.floor((balance ?? 0) * 100) / 100).toFixed(2)}</h3>
                    </div>
                    
                    <div className="space-y-4">
                        <div>
                            <label className="block text-gray-600 mb-1">Chave PIX</label>
                            <div className="flex items-center bg-gray-100 rounded p-2">
                                <FaKey className="text-gray-500 mr-2" />
                                <input 
                                    type="text" 
                                    placeholder="Insira sua chave PIX" 
                                    className="w-full bg-gray-100 outline-none" 
                                    value={pixKey} 
                                    onChange={handlePixKeyChange}
                                    onBlur={handlePixKeyValidation}
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-gray-600 mb-1">Valor que deseja sacar</label>
                            <div className="flex items-center bg-gray-100 rounded p-2">
                                <FaMoneyBill className="text-gray-500 mr-2" />
                                <input 
                                    type="number" 
                                    placeholder="Insira o valor" 
                                    className="w-full bg-gray-100 outline-none" 
                                    value={amount} 
                                    onChange={handleAmountChange}
                                />
                            </div>
                        </div>

                        <button 
                            onClick={handleWithdraw} 
                            className={`w-full py-2 rounded-lg mt-6 transition ${isButtonDisabled ? 'bg-[#00dc84] cursor-not-allowed' : 'bg-[#00dc84] hover:bg-gray-800 text-white'}`}
                            disabled={isButtonDisabled || isLoading}
                        >
                            { isLoading ? 'Carregando...' : 'Confirmar saque' }
                        </button>
                    </div>
                    {errorMessage && <p className="text-red-600 text-center mt-2">{errorMessage}</p>}
                    {error && <p className="text-red-500 text-sm mb-4 text-center">{error}</p>}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Withdraw;
