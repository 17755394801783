import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaMoneyBill, FaPercentage, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import ErrorFallback from '../KYC/ErrorFallback';
import { AccountInfoController } from '../../controller/AccountInfoController/AccountInfoController';
import { BRLA_CONTRACT_ADDRESSES, POLYGONURLC } from '../../variaveis/variaveis';
import { BRLAContractAbi } from '../../utils/abis';
import { StakeController } from '../../controller/StakeController/StakeController';
import Web3 from 'web3';

interface AccountInfo {
    id: string;
    email: string;
    walletAddress: string;
    createdAt: string;
    updatedAt: string;
    taxId: string;
    level: number;
    status: string;
}

const Investment: React.FC = () => {
    const [investAmount, setInvestAmount] = useState('');
    const [freePercentage, setFreePercentage] = useState(0);
    const [infoData, setInfoData] = useState<AccountInfo | null>(null);
    const [error, setError] = useState('');
    const [hasError, setHasError] = useState(false);
    const [balanceError, setBalanceError] = useState(false);
    const [balance, setBalance] = useState<number | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBalance, setIsLoadingBalance] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const amountNumber = parseFloat(investAmount);
        const isValidAmount = balance && amountNumber > 0 && amountNumber <= balance;
        const isValidPercentage = freePercentage >= 1 && freePercentage <= 50;

        setIsButtonDisabled(!(isValidAmount && isValidPercentage));
        setError('');

        if (!isValidAmount) {
            setError('Por favor, insira um valor de investimento válido dentro do saldo disponível.');
        } else if (!isValidPercentage) {
            setError('Por favor, insira um percentual free entre 1 e 50.');
        }
    }, [investAmount, freePercentage, balance]);

    const handleInvest = async () => {
        const amountNumber = parseFloat(investAmount);
    
        if (!amountNumber || !freePercentage) {
            setError("Por favor, insira um valor e percentual válidos.");
            return;
        }
        
        const amountCents = parseFloat((amountNumber * 5).toFixed(0));
        const data = { amountBrla: amountCents, freePercentage };
        setIsLoading(true);
    
        try {
            const response = await StakeController(data);
            if (response.status === 201) {
                toast.success("Solicitação de investimento enviada com sucesso!");
                setTimeout(() => navigate("/"), 3000);
            } else {
                toast.error("Erro ao enviar solicitação de investimento.");
            }
        } catch (error: any) {
            if (error.status === 401) {
                navigate('/login')
            }
            toast.error("Erro na solicitação: " + (error.response?.data?.error || error.message));
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAccountInfo = async () => {
        try {
            const info = await AccountInfoController();
            if (info && info.data) {
                setInfoData(info.data);
                setHasError(false);
            }
        } catch (error: any) {
            if (error.status === 401) {
                navigate('/login');
            } else {
                setHasError(true);
            }
        }
    };

    const fetchBalance = async () => {
        if (!infoData?.walletAddress) return;
        const web3 = new Web3(new Web3.providers.HttpProvider(POLYGONURLC));
        const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[80001];
        const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);

        try {
            const brlaBalance = await contract.methods.balanceOf(infoData.walletAddress).call();
            if (brlaBalance) {
                const balanceInEther = parseFloat(web3.utils.fromWei(brlaBalance.toString(), 'ether'));
                setBalance(balanceInEther);
                setBalanceError(false);
            }
        } catch (error) {
            console.error("Error fetching balance:", error);
            setBalanceError(true);
        } finally {
            setIsLoadingBalance(false);
        }
    };

    useEffect(() => {
        fetchAccountInfo();
    }, [navigate]);

    useEffect(() => {
        if (infoData) {
            fetchBalance();
        }
    }, [infoData]);

    if (!infoData || isLoadingBalance) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Skeleton />
            </div>
        );
    }

    if (hasError || balanceError) {
        return <ErrorFallback onRetry={() => { fetchAccountInfo(); fetchBalance(); }} />;
    }

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8 grid grid-cols-1 gap-8">
                <button 
                    onClick={() => navigate('/')} 
                    className="self-start mb-4 text-gray-700 hover:text-gray-900"
                >
                    <FaChartLine className="inline-block mr-2" />
                    <span>Voltar para o Dashboard</span>
                </button>

                <div className="bg-gray-50 rounded-lg p-6 shadow-sm flex flex-col justify-between">
                    <div>
                        <h2 className="text-xl font-semibold text-gray-800 text-center mb-4">Investir</h2>
                        <div className="mb-6 text-center">
                            <span className="text-gray-600">Saldo disponível para investimento</span>
                            <h3 className="text-2xl font-bold text-gray-800 mt-1">R$ {(Math.floor((balance ?? 0) * 100) / 100).toFixed(2)}                            </h3>
                        </div>

                        <div className="space-y-4">
                            <div>
                                <label className="block text-gray-700 mb-1">Valor que deseja investir</label>
                                <div className="flex items-center bg-white border border-gray-300 rounded p-2">
                                    <FaMoneyBill className="text-gray-500 mr-2" />
                                    <input 
                                        type="number" 
                                        placeholder="Insira o valor" 
                                        className="w-full bg-white outline-none" 
                                        value={investAmount} 
                                        onChange={(e) => setInvestAmount(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="block text-gray-700 mb-1">
                                    Percentual Free (maior % free = maior tempo para saque)
                                </label>
                                <div className="flex items-center bg-white border border-gray-300 rounded p-2">
                                    <FaPercentage className="text-gray-500 mr-2" />
                                    <input 
                                        type="number" 
                                        placeholder="Escolha o percentual free (1-50)" 
                                        className="w-full bg-white outline-none" 
                                        value={freePercentage} 
                                        onChange={(e) => setFreePercentage(parseFloat(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>

                        {error && <p className="text-red-500 text-sm text-center mt-4">{error}</p>}
                    </div>

                    <button 
                        onClick={handleInvest} 
                        className={`w-full py-2 rounded-lg mt-6 transition ${isButtonDisabled ? 'bg-[#00dc84] cursor-not-allowed' : 'bg-[#00dc84] text-white hover:bg-green-700'}`}
                        disabled={isButtonDisabled || isLoading}
                    >
                        {isLoading ? 'Carregando...' : 'Confirmar Investimento'}
                    </button>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Investment;
